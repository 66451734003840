/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.js
 * - /npm/react@16.4.0/umd/react.development.js
 * - /npm/react-dom@16.4.0/umd/react-dom.development.js
 * - /npm/prop-types@15.6.1/prop-types.js
 * - /npm/mobx@3.3.0/lib/mobx.umd.js
 * - /npm/mobx-react@4.3.2/index.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
